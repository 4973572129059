import LazySizes from 'lazysizes';
import Link from './components/Link';
import Menu from './components/Menu';

new Menu();

document.querySelectorAll('a').forEach((link) => {
  new Link(link);
});

document.querySelectorAll('.js--page-navigation__container')?.forEach((el) => {
  import('@src/components/PageNavigation').then((module) => {
    new module.default(el);
  });
});

document.querySelectorAll('.splide').forEach((carousel) => {
  import('@src/components/Carousel').then((module) => {
    new module.default(carousel);
  });
});
