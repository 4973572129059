/**
 * Get URL path without the query parameters or hash location.
 *
 * @param {string} url
 *
 * @return {string}
 */
function getCleanPath(url) {
  if (!url) {
    return null;
  }
  const withoutQueryParams = url.split('?')[0];
  const withoutQueryParamsOrHash = withoutQueryParams.split('#')[0];

  return withoutQueryParamsOrHash;
}

/**
 * Is the current href from the uploads directory?
 *
 * @param {string} href
 *
 * @return {boolean}
 */
function isUploadsURL(url) {
  if (!url) {
    return false;
  }

  return new URL(url).pathname.startsWith('/wp-content/uploads/');
}

class Link {
  constructor(element) {
    this.element = element;
    this.connect();
  }

  connect() {
    this.setAsExternalLink();
    this.setAsCurrentLink();
  }

  /**
   * Indicate as current link.
   */
  setAsCurrentLink() {
    const linkHref = getCleanPath(this.element.href);
    const windowHref = getCleanPath(window.location.href);

    if (linkHref === windowHref) {
      this.element.setAttribute('aria-current', 'page');
    }
  }

  /**
   * Apply external link attributes.
   */
  setAsExternalLink() {
    if (this.element.dataset.target === 'self') {
      return;
    }

    const isDifferentHost = this.element.host !== window.location.host;

    if (isDifferentHost || isUploadsURL(this.element.href)) {
      this.element.setAttribute('rel', 'noopener noreferrer');
      this.element.setAttribute('target', '_blank');
      this.element.setAttribute('data-external', '');
    }
  }
}

export default Link;
