import * as focusTrap from 'focus-trap';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

// Interactive element that exits the modal.
const EXIT_CLASS = '.js--menu__exit';

/**
 * Open and close menu modal when trigger is toggled.
 *
 * @see https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/dialog
 */
class Menu {
  constructor() {
    this.trigger = document.querySelector('.js--menu__trigger');

    if (this.trigger) {
      this.connect();
    }
  }

  connect() {
    const modalID = this.trigger.getAttribute('aria-controls');
    this.modal = document.getElementById(modalID);

    if (this.modal) {
      this.exit = this.modal.querySelector(EXIT_CLASS);
      this.trigger.addEventListener('click', () => this.toggle());

      this.trap = focusTrap.createFocusTrap(this.modal, {
        initialFocus: this.modal,
        escapeDeactivates: true,
        onDeactivate: () => this.toggle(false),
      });
    }
  }

  /**
   * Toggle the open / close state of
   * the modal.
   *
   * @param {boolean} open - force open or close.
   */
  toggle(open = null) {
    const wasOpened = this.trigger.getAttribute('aria-expanded') !== 'false';
    const toOpen = open === null ? !wasOpened : open;
    const siteContainer = document.getElementById('site-container');

    this.trigger.setAttribute('aria-expanded', toOpen);
    this.modal.setAttribute('aria-hidden', !toOpen);
    siteContainer.setAttribute('aria-hidden', toOpen);

    this.modal.hidden = !toOpen;

    /**
     * Toggle trap and body scroll, and listen
     * for modal exit.
     */
    if (toOpen) {
      this.modal.scrollTo(0, 0);
      this.trap.activate();
      disableBodyScroll(this.modal);

      this.modal
        .querySelector(EXIT_CLASS)
        ?.addEventListener('click', this.trap.deactivate);
    } else {
      this.trap.deactivate();
      enableBodyScroll(this.modal);
    }
  }
}

export default Menu;
